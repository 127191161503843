const OVER_UNDER = 'OVER_UNDER';
const ODD_EVEN = 'ODD_EVEN';
const YES_NO = 'YES_NO';
const TEAM = 'TEAM';
const COLOUR = 'COLOUR';
const TEXT = 'TEXT';

class PropType {
  static get ALL_TYPES() {
    return [OVER_UNDER, TEAM, YES_NO, ODD_EVEN, COLOUR, TEXT];
  }

  static get ENABLED_TYPES() {
    return [OVER_UNDER, TEAM, YES_NO, ODD_EVEN];
  }

  static get OVER_UNDER() {
    return OVER_UNDER;
  }

  static get ODD_EVEN() {
    return ODD_EVEN;
  }

  static get YES_NO() {
    return YES_NO;
  }

  static get TEAM() {
    return TEAM;
  }

  static get COLOUR() {
    return COLOUR;
  }

  static get TEXT() {
    return TEXT;
  }
}

export default PropType;