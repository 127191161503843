import React, {useEffect, useState} from 'react'
import PbButton from "../common/buttons/PbButton";
import {get, post, put} from "../../shared/services/ApiService";
import {getFirstName, getLastName, getUserEmail, getUserId} from "../../shared/services/AuthService";
import {buildModal, useModal} from "../common/modals/Modal";
import LabeledField from "../common/fields/LabeledField";
import {PageLoader} from "../common/widgets/PageLoader";

export const AccountSettings = () => {
    const [loading, setLoading] = useState(true);
    const [isRequestSent, setRequestSent] = useState(false);
    const [isUserUpdated, setUserUpdated] = useState(false);
    const [user, setUser] = useState({});
    const {setModal} = useModal();

    const loadData = () => {
        get(`api/users/${getUserId()}`).then(response => {
                setUser(response);
                setLoading(false);
            },
            error => {
                setModal(buildModal("Error", error));
                console.error(error);
            });
    }

    useEffect(() => {
        loadData();
    }, [])

    if (loading) {
        return (<PageLoader></PageLoader>);
    }

    const onChange = (fieldName, value) => {
        setUser(prevUser => {
                return {
                    ...prevUser,
                    [fieldName]: value
                }
            }
        );
        setUserUpdated(true);
    }

    const updateUser = () => {
        const request = {
            email: user.email === getUserEmail() ? null : user.email,
            firstName: user.firstName === getFirstName() ? null : user.firstName,
            lastName: user.lastName === getLastName() ? null : user.lastName
        };
        put('api/users', request).then(
            () => {
                setModal(buildModal("Account Updated",
                    "You have successfully updated your account."));
                setUserUpdated(false);
                loadData();
            },
            error => {
                setModal(buildModal("Error", error));
                console.error(error);
            }
        );
    };

    const resetPassword = () => {
        const request = {
            emailAddress: getUserEmail()
        };
        post('api/password/reset/init', request).then(
            () => {
                setRequestSent(true);
                setModal(buildModal("Password Reset",
                    "An email has been sent to you to reset your password."));
            },
            error => {
                setModal(buildModal("Error", "Couldn't complete the ticket."));
                console.error(error);
            }
        );
    };

    return (
        <div className="props-page-body-dark">
            <label className="games-header">Account Settings</label>
            <div className="account-settings">
                <LabeledField name="email" label="Email" type="text" value={user.email}
                              onChange={onChange}></LabeledField>
                <LabeledField name="firstName" label="First Name" type="text" value={user.firstName}
                              onChange={onChange}></LabeledField>
                <LabeledField name="lastName" label="Last Name" type="text" value={user.lastName}
                              onChange={onChange}></LabeledField>
                <PbButton onClick={() => updateUser()} label="Update Account" isDisabled={!isUserUpdated}
                ></PbButton>
                <br/>
                <div>To update your password, click the Reset Password button below</div>
                <PbButton onClick={resetPassword} isDisabled={isRequestSent} label="Reset Password"></PbButton>
            </div>
        </div>
    )
}