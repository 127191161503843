import React from "react";
import {Route, Routes} from "react-router-dom";
import {Dashboard} from "../components/dashboard/Dashboard";
import {Login} from "../components/account/Login";
import {Registration} from "../components/account/Registration";
import {PasswordResetUpdate} from "../components/account/PasswordResetUpdate";
import {PasswordResetInit} from "../components/account/PasswordResetInit";
import {AccountSettings} from "../components/account/AccountSettings";
import {OpenGames} from "../components/game/list/OpenGames";
import {MyGames} from "../components/game/list/MyGames";
import TicketBuilder from "../components/game/manage/TicketBuilder";
import {ResultsEditor} from "../components/game/manage/ResultsEditor";
import KioskView from "../components/game/kiosk/KioskView";
import TransactionHistory from "../components/transaction/TransactionHistory";
import ProtectedRoute from "./ProtectedRoute";
import LobbyView from "../components/game/lobby/LobbyView";

const startGamePermissionError = "You currently don't have access to start your own game. Contact ryan@propsbuddy.com to get started."
export const routes = (
    <Routes>
        <Route path="/*" element={<Dashboard/>}/>
        <Route path="/home" element={<Dashboard></Dashboard>}></Route>
        <Route path="/login" element={<Login></Login>}></Route>
        <Route path="/register" element={<Registration></Registration>}></Route>
        <Route path="/password-reset/update" element={<PasswordResetUpdate></PasswordResetUpdate>}></Route>
        <Route path="/password-reset/init" element={<PasswordResetInit></PasswordResetInit>}></Route>
        <Route path="/account-settings" element={<ProtectedRoute><AccountSettings></AccountSettings></ProtectedRoute>}></Route>
        <Route path="/games/open" element={<ProtectedRoute><OpenGames></OpenGames></ProtectedRoute>}></Route>
        <Route path="/games" element={<ProtectedRoute><MyGames></MyGames></ProtectedRoute>}></Route><Route path="/games/create" element={<ProtectedRoute permission="CREATE_GAME" permissionError={startGamePermissionError}><TicketBuilder></TicketBuilder></ProtectedRoute>}></Route>
        <Route path="/games/lobby/:ticketCode" element={<ProtectedRoute><LobbyView></LobbyView></ProtectedRoute>}></Route>
        <Route path="/games/kiosk/:ticketCode" element={<ProtectedRoute><KioskView></KioskView></ProtectedRoute>}></Route>
        <Route path="/games/results/edit/:ticketCode" element={<ProtectedRoute><ResultsEditor></ResultsEditor></ProtectedRoute>}></Route>
        <Route path="/transactions" element={<ProtectedRoute><TransactionHistory></TransactionHistory></ProtectedRoute>}></Route>
    </Routes>
);