import React from 'react'
import PropTypes from "prop-types";

const LabeledField = ({name, label, type = "text", value, onChange}) => {

    return (
        <div className="pb-field-group">
            <label>{label}</label>
            <input className="pb-field"
                   type={type}
                   value={value}
                   onChange={(e) => onChange(name, e.target.value)}
                   defaultChecked={type === "checkbox" && value}
            ></input>
        </div>
    );
}

LabeledField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
};

export default LabeledField;