import jwt_decode from "jwt-decode";

export const saveToken = (token) => {
    localStorage.setItem('token', token);
}

const getToken = () => {
    return localStorage.getItem('token');
}

const decodeToken = (token) => {
    return jwt_decode(token);
}

export const getUserId = () => {
    return decodeToken(getToken()).userId;
}

export const getUserEmail = () => {
    return decodeToken(getToken()).sub;
}

export const hasPermission = (permission) => {
    return getPermissions().includes(permission);
}

const getPermissions = () => {
    return decodeToken(getToken()).permissions;
}

export const logout = () => {
    localStorage.removeItem('token');
};

export const getFirstName = () => {
    return decodeToken(getToken()).firstName;
}

export const getLastName = () => {
    return decodeToken(getToken()).lastName;
}

const isTokenExpired = (token) => {
    const data = decodeToken(token);
    const now = new Date();
    return now.getTime() > data.exp * 1000;
}

export const isAuthenticated = () => {
    const token = getToken();

    if (!token) {
        return false;
    }

    if (isTokenExpired(token)) {
        logout();
        return false;
    }

    return true;
}