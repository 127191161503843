import React from "react";
import {buildModal, useModal} from "../../../common/modals/Modal";
import {translate} from "../../../../shared/services/LocalizationService";
import DateService from "../../../../shared/services/DateService";

export const TicketDetails = ({ticket}) => {
    const {setModal} = useModal();

    const onClick = (ticket) => {
        let adminFee;
        if (ticket.adminFeeFormat === 'PERCENTAGE') {
            adminFee = ticket.adminFeeValue + '%';
        } else if (ticket.adminFeeFormat === 'AMOUNT') {
            adminFee = '$' + ticket.adminFeeValue;
        } else {
            adminFee = '$0';
        }
        const startTime = DateService.parseDate(ticket.startTime, 'MMM D h:mm A');

        const modalBody = (
            <div className="ticket-details">
                <div className="ticket-detail-row">
                    <b>Locks at:</b> {startTime}
                </div>
                <div className="ticket-detail-row">
                    <b>Payout:</b> {translate(ticket.payoutFormat)}
                </div>
                <div className="ticket-detail-row">
                    <b>Buy-In:</b> ${ticket.cost}
                </div>
                <div className="ticket-detail-row">
                    <b>Admin Fee:</b> {adminFee}
                </div>
                <div className="ticket-detail-row">
                    <b>Host:</b> {ticket.adminName}
                </div>
                <div className="ticket-detail-row">
                    <b>Status:</b> {translate(ticket.status)}
                </div>
            </div>
        )

        const modal = buildModal("Ticket Details", modalBody)
        setModal(modal);
    }

    const onButtonClick = (e) => {
        // Prevent parent on click when clicking action button inside PbContainer
        e.stopPropagation();
        onClick(ticket);
    }

    return (
        <a onClick={onButtonClick} className="ticket-details-btn">
            Details <i className="fas fa-info-circle"></i>
        </a>
    );
}