import React from 'react'
import PropTypes from "prop-types";

import DateService from '../../../../shared/services/DateService';

export const GameItem = ({game, onClick, hideDate = false}) => {
    const gameDate = DateService.parseDate(game.startTime, 'MMM D');
    const startTime = DateService.parseDate(game.startTime, 'h:mm A');

    const onItemClicked = () => {
        if (onClick) {
            onClick(game.id);
        }
    }

    let boxscore, bettingLine, homeScore, awayScore, homeSpread, totalPoints;
    if (game.externalGame) {
        boxscore = game.externalGame.boxscore;
        bettingLine = game.externalGame.bettingLine;
        if (boxscore) {
            homeScore = boxscore.home.stats.SCORE;
            awayScore = boxscore.away.stats.SCORE;
        } else if (bettingLine) {
            homeSpread = bettingLine.spread > 0 ? '+' + bettingLine.spread : bettingLine.spread;
            totalPoints = bettingLine.totalPoints ?? `O/U: ${bettingLine.totalPoints}`;
        }

    }

    const isClickable = !!onClick;

    return (
        <div className={`game-item ${isClickable ? 'clickable' : ''}`} onClick={onItemClicked}>
            {!hideDate &&
                < div className="game-item-date">
                    {gameDate} {startTime}
                </div>
            }
            <div className="game-item-teams">
                <div className="team-label">{game.awayTeam}
                    {totalPoints && <span className="betting-line">{`O/U: ${totalPoints}`}</span>}
                    {boxscore && <span className="team-score">{awayScore}</span>}
                    </div>
                <div className="vs-label">vs.</div>
                <div className="team-label">{game.homeTeam}
                    {homeSpread && <span className="betting-line">{homeSpread}</span>}
                    {boxscore && <span className="team-score">{homeScore}</span>}
                </div>
            </div>
        </div>
    );
};

GameItem.propTypes = {
    game: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    hideDate: PropTypes.bool
};