import React, {useEffect, useState} from 'react'
import Leaderboard from '../lobby/Leaderboard.jsx';
import {get} from "../../../shared/services/ApiService";
import {PageLoader} from "../../common/widgets/PageLoader";
import {useParams} from "react-router-dom";
import ResultsTimeline from "../lobby/ResultsTimeline";

const KioskView = () => {
    const [ticket, setTicket] = useState(null);
    const [loading, setLoading] = useState(true);
    const params = useParams();

    const loadData = () => {
        const ticketCode = params.ticketCode;
        get(`api/tickets/${ticketCode}?attachEntries=true&attachTimeline=true`).then(response => {
            setTicket(response);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadData();
        const intervalId = setInterval(() => loadData(), 20000)
        return () => clearInterval(intervalId);
    }, []);

    if (loading) {
        return (
            <PageLoader></PageLoader>
        );
    }

    return (
        <div className="results-kiosk">
            <Leaderboard ticket={ticket}></Leaderboard>
            <ResultsTimeline ticket={ticket} showAll={true}></ResultsTimeline>
        </div>
    );
}

export default KioskView;