import React, {useEffect, useState} from 'react'
import Leaderboard from './Leaderboard.jsx';
import PbTabContainer from "../../common/containers/PbTabContainer";
import {useNavigate, useParams} from "react-router-dom";
import {get} from "../../../shared/services/ApiService";
import {PageLoader} from "../../common/widgets/PageLoader";
import {getUserId} from "../../../shared/services/AuthService";
import InviteFriendButton from "./InviteFriendButton";
import PropEntry from "../../prop/entry/PropEntry";
import {TicketAdminView} from "../manage/TicketAdminView";
import MessageDrafter, {MESSAGE_TYPE_ADMIN} from "./MessageDrafter";
import GameInfo from "./GameInfo";
import ResultsTimeline from "./ResultsTimeline";
import ResultsTable, {VIEW_RESOLVED, VIEW_UNRESOLVED} from "./table/ResultsTable";
import Ticket from "../common/game-item/Ticket";
import {buildModal, useModal} from "../../common/modals/Modal";

const LobbyView = () => {
    const [loading, setLoading] = useState(true);
    const [ticket, setTicket] = useState(null);
    const params = useParams();
    const {setModal} = useModal();
    const navigate = useNavigate();

    const loadData = () => {
        const ticketCode = params.ticketCode;
        get(`api/tickets/${ticketCode}?attachEntries=true&attachTimeline=true`).then(response => {
                setTicket(response);
                setLoading(false);
            },
            error => {
                const modal = buildModal("Couldn't Load Ticket", error);
                setModal(modal);
                console.error(error);
                navigate(-1);
            });
    }

    useEffect(() => {
        loadData();
    }, []);

    if (loading) {
        return (
            <PageLoader></PageLoader>
        );
    }

    const currentUserId = getUserId();
    const isGameAdmin = currentUserId === ticket.adminId;
    const isUserEntered = ticket.entries.filter((entry) => entry.userId === currentUserId).length > 0;

    // If user hasn't entered yet, don't show other lobby options
    if (!isUserEntered && !isGameAdmin) {
        return (
            <div>
                <div className="props-page-body-dark">
                    <Ticket ticket={ticket}>
                        <GameInfo ticket={ticket}></GameInfo>
                        <PropEntry ticket={ticket}></PropEntry>
                    </Ticket>
                </div>
            </div>
        );
    }

    let tabs;

    switch (ticket.status) {
        case 'DRAFT':
            if (isGameAdmin) {
                return (
                    <div className="props-page-body-dark">
                        <Ticket ticket={ticket}>
                            <TicketAdminView ticket={ticket}></TicketAdminView>
                        </Ticket>
                    </div>
                );
            }
            break;
        case 'PRE_GAME':
            tabs = [
                {
                    tabName: "My Picks", tabIcon: "fas fa-edit",
                    tabContent: <PropEntry ticket={ticket}></PropEntry>
                },
                {
                    tabName: "Details", tabIcon: "fas fa-info-circle",
                    tabContent: <GameInfo ticket={ticket}></GameInfo>
                },
                {
                    tabName: "Who's In", tabIcon: "fas fa-users",
                    tabContent: <Leaderboard ticket={ticket}></Leaderboard>
                },
                {
                    tabName: "Invite", tabIcon: "fas fa-envelope",
                    tabContent: <InviteFriendButton ticket={ticket}></InviteFriendButton>
                },
                {
                    tabName: "Help", tabIcon: "fas fa-question-circle",
                    tabContent: <MessageDrafter ticket={ticket} messageType={MESSAGE_TYPE_ADMIN}></MessageDrafter>
                }
            ];
            break;
        case 'LIVE':
        case 'POST_GAME':
        case 'COMPLETED':
            tabs = [
                {
                    tabName: "Leaderboard", tabIcon: "fas fa-list-ol",
                    tabContent: <Leaderboard ticket={ticket}></Leaderboard>
                },
                {
                    tabName: "Activity", tabIcon: "fas fa-chart-line",
                    tabContent: <ResultsTimeline ticket={ticket}></ResultsTimeline>
                },
                {
                    tabName: "Resolved", tabIcon: "fas fa-check-circle",
                    tabContent: <ResultsTable viewMode={VIEW_RESOLVED} ticket={ticket}></ResultsTable>
                },
                {
                    tabName: "Unresolved", tabIcon: "fas fa-clock",
                    tabContent: <ResultsTable viewMode={VIEW_UNRESOLVED} ticket={ticket}></ResultsTable>
                },
                {
                    tabName: "Help", tabIcon: "fas fa-question-circle",
                    tabContent: <MessageDrafter ticket={ticket} messageType={MESSAGE_TYPE_ADMIN}></MessageDrafter>
                }
            ];
            break;
        default:
            if (isGameAdmin) {
                tabs = [];
                break;
            }
            tabs = [
                {
                    tabName: "Not Available", tabIcon: "fas fa-list-ol",
                    tabContent: <div>Game content not available</div>
                },
            ]
            break;
    }

    if (isGameAdmin) {
        tabs.push({
                tabName: "Admin", tabIcon: "fas fa-user-cog",
                tabContent: <TicketAdminView ticket={ticket}></TicketAdminView>
            }
        );
    }

    return (
        <div className="props-page-body-dark">
            <Ticket ticket={ticket}>
                <PbTabContainer tabs={tabs}></PbTabContainer>
            </Ticket>
        </div>
    );
}

export default LobbyView;