import React, {useState} from 'react'
import logo from '../../public/logo-v2.png';
import '../../public/privacypolicy.txt';
import {post, postLogin} from "../../shared/services/ApiService";
import {HOME, LOGIN, REGISTER} from "../../shared/constants/RouteContants";
import {saveToken} from "../../shared/services/AuthService";
import {useLocation, useNavigate} from "react-router-dom";
import {validateEmail} from "../../shared/services/EmailValidator";

export const Registration = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [referrerName, setReferrerName] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const createRegisterRequest = () => {
        return {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            referrerName: referrerName
        };
    };

    const validateData = () => {
        if (!firstName || !lastName || !email || !password) {
            setError("Please fill all required fields");
            return false;
        }

        if (!validateEmail(email)) {
            setError("Invalid Email Address");
            return false;
        }

        if (password.length < 6) {
            setError("Invalid Password: Must be at least 6 characters");
            return false;
        }

        return true;
    };

    const register = () => {
        if (!validateData()) {
            return;
        }

        post('api/users', createRegisterRequest()).then(() => {
                const loginRequest = {
                    email: email,
                    password: password
                };
                postLogin(loginRequest).then(
                    token => {
                        saveToken(token);
                        if (location.pathname === REGISTER) {
                            navigate(HOME);
                        } else {
                            window.location.reload();
                        }
                    },
                    error => {
                        console.error(error);
                        navigate(LOGIN);
                    }
                );
            },
            error => {
                setError(error);
            }
        );
    }

    return (
        <div className="account-container">
            <div className="account-logo-container">
                <img className="account-logo" src={logo} alt="logo"></img>
            </div>
            <div className="account-error-container">
                {error &&
                    <div className="account-error">
                        {error}
                    </div>
                }
            </div>
            <div className="field-container">
                <div className="account-field">
                    <input type="text"
                           value={firstName}
                           onChange={(e) => setFirstName(e.target.value)}
                           placeholder="First Name"></input>
                </div>
                <div className="account-field">
                    <input type="text"
                           value={lastName}
                           onChange={(e) => setLastName(e.target.value)}
                           placeholder="Last Name"></input>
                </div>
                <div className="account-field">
                    <input type="text"
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           placeholder="Email"></input>
                </div>
                <div className="account-field">
                    <input ng-keyup="$event.keyCode == 13 && $ctrl.register()" type="password"
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                           placeholder="Password"></input>
                </div>
                <div className="account-field">
                    <div>Did someone refer you to Props Buddy?</div>
                    <input type="text" value={referrerName}
                           onChange={(e) => setReferrerName(e.target.value)}
                           placeholder="Referrer Full Name"></input>
                </div>
                <div className="fine-print">
                    By clicking Register, you agree to receive emails from Props Buddy and you accept our
                    <a target="_blank" rel="noreferrer"
                       href="https://drive.google.com/open?id=1OUTtYIElGeYDA70wDVgRjEU0qK-c7d4L">privacy
                        policy</a>.
                </div>
            </div>

            <div className="button-container">
                <button className="account-btn" onClick={register}>Register</button>
            </div>
        </div>
    )
}
