import React from 'react';
import PropTypes from "prop-types";

const PbContainerHeader = ({label, iconClass, actionButton, collapsed, collapsible, onClick}) => {
    const toggleVisibility = () => {
        if (onClick) {
            onClick();
        }
    }

    const collapseIcon = collapsed ?
        <i className="fas fa-angle-double-down"></i> : <i className="fas fa-angle-double-up"></i>;

    return (
        <div className={`container-header ${collapsible ? "collapsable" : ""}`}  onClick={() => toggleVisibility()}>
            {iconClass &&
                <div className="header-icon">
                    <i className={iconClass}></i>
                </div>
            }
            <div className="header-title">
                {label}
            </div>
            <div className="header-action">
                {actionButton}
            </div>
            {collapsible &&
                <a className="collapse-btn">{collapseIcon}</a>
            }
        </div>
    )
}

PbContainerHeader.propTypes = {
    label: PropTypes.string.isRequired,
    iconClass: PropTypes.string,
    actionButton: PropTypes.any,
    onToggleCollapse: PropTypes.func
}

export default PbContainerHeader;