import React, {useCallback, useEffect, useState} from 'react'
import BasicModal from "./BasicModal";
import CustomPropBuilder from "../../prop/builder/CustomPropBuilder";
import PropType from "../../../shared/constants/PropType";
import OverUnderPropBuilder from "../../prop/builder/OverUnderPropBuilder";

const ModalContext = React.createContext();

export const closeModal = (setModal) => {
    const input = document.getElementById("props-modal");
    if (!input) {
        return;
    }
    const event = new KeyboardEvent('keyup', {keyCode: 27});
    input.dispatchEvent(event);
    setModal();
}



export const buildPropBuilderModal = (game, type, onPropSaved) => {
    if (type === PropType.OVER_UNDER) {
        return (<OverUnderPropBuilder game={game} onPropSaved={onPropSaved}></OverUnderPropBuilder>);
    } else {
        return (<CustomPropBuilder game={game} type={type} onPropSaved={onPropSaved}></CustomPropBuilder>);
    }
}

const buildModal = (title, description, onAccept, acceptLabel, onCancel, cancelLabel) => {
    return (
        <BasicModal title={title} description={description}
                    onAccept={onAccept} acceptLabel={acceptLabel}
                    onCancel={onCancel} cancelLabel={cancelLabel}
        ></BasicModal>
    )
}

const Modal = ({modal, unSetModal}) => {
    useEffect(() => {
        const bind = e => {
            if (e.keyCode !== 27) {
                return;
            }

            // if (document.activeElement && ['INPUT', 'SELECT'].includes(document.activeElement.tagName)) {
            //     return;
            // }
            // unSetModal();
        }

        document.addEventListener('keyup', bind)
        return () => document.removeEventListener('keyup', bind)
    }, [modal, unSetModal]);

    return (
        <div id="props-modal" className="props-modal">
            <div className="props-modal-overlay"/>
            {modal}
        </div>
    )
}

const ModalProvider = (props) => {
    const [modal, setModal] = useState();
    const unSetModal = useCallback(() => {
        setModal();
    }, [setModal])

    return (
        <ModalContext.Provider value={{unSetModal, setModal}} {...props} >
            {props.children}
            {modal && <Modal modal={modal} unSetModal={unSetModal}/>}
        </ModalContext.Provider>
    )
}

const useModal = () => {
    const context = React.useContext(ModalContext)
    if (context === undefined) {
        throw new Error('useModal must be used within a UserProvider')
    }

    return context
}

export {ModalProvider, useModal, buildModal}