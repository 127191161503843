import React, {useState} from 'react';
import PbButton from '../../common/buttons/PbButton.jsx';
import LabeledField from "../../common/fields/LabeledField";
import LabeledDateField from "../../common/fields/LabeledDateField";
import PbContainer from "../../common/containers/PbContainer";
import Sport from "../../../shared/constants/Sport";


const defaultGame = () => {
    const game = {};

    game.startTime = new Date();
    game.homeTeam = "Home Team";
    game.awayTeam = "Away Team";
    game.sport = Sport.CUSTOM;
    return game;
}

const CustomGameBuilder = ({onSave}) => {
    const [game, setGame] = useState(defaultGame());

    const onChange = (fieldName, value) => {
        setGame(prevGame => {
                return {
                    ...prevGame,
                    [fieldName]: value
                }
            }
        );
    }

    const isGameComplete = () => {
        return !!game.homeTeam && !!game.awayTeam && !!game.startTime;
    }

    return (
        <PbContainer label="Custom Game Details Details">
            <div className="game-details">
                Note: Custom games are not eligible for live scoring updates. The game admin must manually resolve all props
            </div>
            <div className="game-details">
                <div className="pb-field-row">
                    <LabeledField name="name" label="Game Name (Optional)" type="text" value={game.name}
                                  onChange={onChange}></LabeledField>
                    <LabeledDateField name="startTime" label="Start Time" value={game.startTime}
                                      onChange={onChange}></LabeledDateField>
                </div>
                <div className="pb-field-row">
                    <LabeledField name="homeTeam" label="Home Team" type="text" value={game.homeTeam}
                                  onChange={onChange}></LabeledField>
                    <LabeledField name="awayTeam" label="Away Team" value={game.awayTeam}
                                      onChange={onChange}></LabeledField>
                </div>
            </div>
            <div className="pb-btn-row">
                <PbButton label="Add Game" isDisabled={!isGameComplete()} onClick={() => onSave(game)}></PbButton>
            </div>
        </PbContainer>
    );
}

export default CustomGameBuilder;