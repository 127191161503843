import React from 'react'

import PropRow from "./PropRow";
import PropType from "../../../shared/constants/PropType";
import PbContainer from "../../common/containers/PbContainer";
import {translate} from "../../../shared/services/LocalizationService";

export const PropsEditor = ({game, viewModel, onPropUpdated}) => {

    const getCurrentValue = (prop) => {
        if (viewModel) {
            return viewModel[prop.key];
        }
        return prop.answer;
    }

    const getPropsByType = (type) => {
        return game.props.sort((a, b) => a.order > b.order ? 1 : -1)
            .filter(prop => prop.type === type)
            .map((prop) => {
                return (
                    <div key={prop.id} className="pb-row">
                        <PropRow game={game} propData={prop}
                                 currentValue={getCurrentValue(prop)}
                                 onPropUpdated={onPropUpdated}
                        ></PropRow>
                    </div>
                )
            });
    }

    return PropType.ENABLED_TYPES.map(type => {
        const props = getPropsByType(type);
        if (props.length === 0) {
            return <div key={type}></div>;
        }
        return <div key={type}>
            <PbContainer label={translate(type)}>{props}</PbContainer>
        </div>
    });
}
