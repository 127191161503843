import React from 'react';
import {GameItem} from "../common/game-item/GameItem";
import LabeledCheckbox from "../../common/fields/LabeledCheckbox";
import LabeledField from "../../common/fields/LabeledField";

const TicketBuilderGameItem = ({game, updateGame, deleteGame}) => {
    const onChange = (fieldName, value) => {
        game[fieldName] = value;
        updateGame(game);
    }

    return (
        <div className="ticket-builder-game">
            <GameItem game={game}></GameItem>
            <LabeledField name="totalPoints" label="O/U" type="number" value={game.totalPoints || ""}
                          onChange={onChange}></LabeledField>
            <LabeledField name="homeSpread" label="Home Spread" type="number" value={game.homeSpread || ""}
                          onChange={onChange}></LabeledField>
            <LabeledCheckbox name="addDefaultProps" label="Default Props"
                             value={game.addDefaultProps} onChange={onChange}></LabeledCheckbox>
            <i onClick={deleteGame} className="fas fa-times-circle"></i>
        </div>
    )
}

export default TicketBuilderGameItem;