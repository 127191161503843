import React from 'react'
import {put} from "../../../shared/services/ApiService";
import {EDIT_RESULTS, GAME_RESULTS_KIOSK, HOME} from "../../../shared/constants/RouteContants";
import {buildModal, useModal} from "../../common/modals/Modal";
import {translate} from "../../../shared/services/LocalizationService";
import PbButton from "../../common/buttons/PbButton";
import {useNavigate} from "react-router-dom";

const TicketManager = ({ticket}) => {
    const {setModal} = useModal();
    const navigate = useNavigate();

    const updateTicketState = (newStatus) => {
        const sendUpdate = () => {
            const request = {
                status: newStatus
            }
            put(`api/tickets/${ticket.id}`, request).then(() => {
                    if (newStatus === 'DELETED') {
                        navigate(HOME);
                    } else {
                        window.location.reload();
                    }
                },
                error => {
                    const modal = buildModal("Error", "Couldn't update game status.");
                    setModal(modal);
                    console.error(error);
                });
        }

        if (ticket.status === 'DRAFT' && newStatus === 'PRE_GAME') {
            sendUpdate()
        } else {
            // Confirm user wishes to update when making consequential status changes
            setModal(buildModal("Ticket Status",
                `Are you sure you wish to update the game to ${translate(newStatus)}?`,
                () => sendUpdate()));
        }
    }

    const getContents = () => {
        switch (ticket.status) {
            case 'DRAFT':
                return <div className="pb-btn-row">
                    <PbButton label="Delete Ticket"
                              btnClass="secondary"
                              onClick={() => updateTicketState('DELETED')}
                    ></PbButton>
                    <PbButton label="Publish Ticket"
                              onClick={() => updateTicketState('PRE_GAME')}
                    ></PbButton>
                </div>;
            case 'PRE_GAME':
                return <div>
                    <div className="games-header">Manage Status</div>
                    <div>
                        <b>Return to Draft</b> - Pull the game back to make if you need to make an update to your props
                        and disable further updates from users temporarily.<br/>
                        <b>Lock Ticket</b> - Manually override the automatic lock process if the events get started
                        earlier than the planned ticket lock time.
                    </div>
                    <div className="pb-btn-row-bordered">
                        <PbButton label="Return to Draft"
                                  onClick={() => updateTicketState('DRAFT')}
                        ></PbButton>
                        <PbButton label="Lock Ticket"
                                  onClick={() => updateTicketState('LIVE')}
                        ></PbButton>
                    </div>
                </div>;
            case 'LIVE':
                return <div>
                    <div className="games-header">Manage Results</div>
                    <div>Open the Results Editor to manage any non-auto props in your ticket.</div>
                    <div className="pb-btn-row-bordered">
                        <PbButton label="Update Results"
                                  onClick={() => navigate(EDIT_RESULTS + ticket.code)}
                        ></PbButton>
                    </div>
                    <br/>
                    <div className="games-header">Manage Status</div>
                    <div>
                        <b>Complete Ticket</b> - Complete the game and crown the champ when all games in the ticket
                        have ended and the prop results have been finalized.<br/>
                        <b>Unlock Ticket</b> - Temporarily unlock the ticket to allow a user to get their picks in or
                        fix a mistake. Note: The game will stay unlocked for one minute before being auto-locked.
                    </div>
                    <div className="pb-btn-row-bordered">
                        <PbButton label="Unlock Ticket"
                                  onClick={() => updateTicketState('PRE_GAME')}
                        ></PbButton>
                        <PbButton label="Complete Ticket"
                                  onClick={() => updateTicketState('POST_GAME')}
                        ></PbButton>
                    </div>
                </div>;
            case 'POST_GAME':
            case 'COMPLETED':
                return <div>
                    <div className="games-header">Manage Status</div>
                    <div>
                        <b>Reopen Ticket</b> - Re-open the ticket if there was an error in the results and updates need
                        to be made. Warning: You will need to reach out to the previous winners to inform them that their
                        winner emails are no longer valid.<br/>
                        <b>Archive Ticket</b> - All but erase the ticket from the history of the Props Buddy if you no
                        longer wish this ticket to be visible to anyone.
                    </div>
                    <div className="pb-btn-row-bordered">
                        <PbButton label="Reopen Ticket"
                                  onClick={() => updateTicketState('LIVE')}
                        ></PbButton>
                        <PbButton label="Archive Ticket"
                                  onClick={() => updateTicketState('ARCHIVED')}
                        ></PbButton>
                    </div>
                </div>;
            default:
                return <div></div>;
        }
    }


    return <div className="ticket-manager">
        {getContents()}
    </div>
}

export default TicketManager;