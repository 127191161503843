//TODO: Eventually move this to an en/fr file
const messages = new Map([
    ['WINNER_TAKE_ALL', 'Winner Take All'],
    ['TOP_2', 'Top 2 (80/20)'],
    ['TOP_3', 'Top 3 (70/20/10)'],
    ['GOALS', 'goals'],
    ['ASSISTS', 'assists'],
    ['SHOTS', 'shots'],
    ['PIMS', 'penalty minutes'],
    ['FACEOFF_WINS', 'faceoff wins'],
    ['PASSING_YARDS', 'passing yards'],
    ['PASSING_COMPLETIONS', 'completions'],
    ['PASSING_TDS', 'passing TDs'],
    ['PASSING_INTS', 'interceptions'],
    ['RUSHING_YARDS', 'rushing yards'],
    ['RUSHING_ATTEMPTS', 'rushing attempts'],
    ['RUSHING_TDS', 'rushing TDs'],
    ['RUSHING_LONG', 'longest rush'],
    ['RECEIVING_YARDS', 'receiving yards'],
    ['RECEPTIONS', 'receptions'],
    ['RECEIVING_TDS', 'receiving TDs'],
    ['RECEIVING_LONG', 'longest reception'],
    ['DRAFT', 'DRAFT'],
    ['PRE_GAME', 'PRE-GAME'],
    ['LIVE', 'LIVE'],
    ['POST_GAME', 'POST-GAME'],
    ['COMPLETED', 'COMPLETED'],
    ['ARCHIVED', 'ARCHIVED'],
    ['DELETED', 'DELETED'],
    ['TEAM', 'Team'],
    ['OVER_UNDER', 'Over/Under'],
    ['YES_NO', 'Yes/No'],
    ['ODD_EVEN', 'Odd/Even']
]);

export const translate = (key) => {
    const localizedString = messages.get(key);
    return localizedString ? localizedString : key;
}