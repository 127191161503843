import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {del} from "../../../shared/services/ApiService";
import {buildModal, buildPropBuilderModal, useModal} from "../../common/modals/Modal";
import PropType from "../../../shared/constants/PropType";
import PbContainer from "../../common/containers/PbContainer";
import PropBuilderRow from "./PropBuilderRow";
import {translate} from "../../../shared/services/LocalizationService";

const PropBuilder = ({game}) => {
    const [props, setProps] = useState(game.props || []);
    const {setModal} = useModal();

    useEffect(() => {
        game.props = props
    }, [props]);

    const addProp = (type) => {
        const modal = buildPropBuilderModal(game, type, addSavedProp)
        setModal(modal);
    }

    const addSavedProp = (newProp) => {
        setProps(current => [...current, newProp])
    }

    const updateProp = (updatedProp) => {
        const currentProps = [...props];
        const index = currentProps.findIndex((prop) => prop.id === updatedProp.id)
        currentProps[index] = updatedProp;
        setProps(currentProps);
    }

    const deleteProp = (propId) => {
        del('api/tickets/games/props', propId).then(() => {
                const newProps = [...props]
                const index = newProps.findIndex((prop) => prop.id === propId)
                newProps.splice(index, 1);
                setProps(newProps);
            },
            error => {
                const modal = buildModal("Error", "Couldn't delete prop.");
                setModal(modal);
                console.error(error);
            });
    };

    const getPropsByType = (type) => {
        const filteredProps = props.sort((a, b) => a.order > b.order ? 1 : -1)
            .filter(prop => prop.type === type);

        if (filteredProps.length === 0) {
            return <div className="pb-row">No props</div>
        }

        return filteredProps.map((prop, index) => {
            return (
                <div className="pb-row" key={prop.id}>
                    <PropBuilderRow
                        prop={prop}
                        orderBefore={index === 0 ? null : filteredProps[index - 1].order}
                        orderAfter={index === filteredProps.length - 1 ? null : filteredProps[index + 1].order}
                        deleteProp={() => deleteProp(prop.id)}
                        updateProp={(p) => updateProp(p)}
                    ></PropBuilderRow>
                </div>
            )
        });
    }

    const buildAddPropButton = (type) => <a onClick={() => {
        addProp(type)
    }} className="ticket-details-btn">
        Add Prop <i className="fas fa-plus-circle"></i>
    </a>

    const propRows = PropType.ENABLED_TYPES.map(type => {
        return <PbContainer label={translate(type)} actionButton={buildAddPropButton(type)}>
            {getPropsByType(type)}
        </PbContainer>
    });

    return (
        <div>
            <div className="game-props">
                {propRows}
            </div>
        </div>
    )
}

PropBuilder.propTypes = {
    game: PropTypes.object.isRequired
}

export default PropBuilder;