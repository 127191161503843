import React, {useEffect, useState} from 'react'

import {GAME_LOBBY} from "../../../shared/constants/RouteContants";
import {get} from "../../../shared/services/ApiService";
import {PageLoader} from "../../common/widgets/PageLoader";
import {useNavigate} from "react-router-dom";
import TicketCard from "../common/ticket-card/TicketCard";
import SubmitTextField from "../../common/fields/SubmitTextField";

export const OpenGames = () => {
    const [tickets, setTickets] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const loadData = () => {
        get('api/tickets?invites=true').then(response => {
            setTickets(response);
            setLoading(false);
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    if (loading) {
        return (
            <div className="props-page-body-dark">
                <PageLoader></PageLoader>
            </div>
        );
    }

    const goToTicket = (ticketCode) => {
        navigate(GAME_LOBBY + ticketCode);
    }

    const invitedTickets = tickets.map((ticket, index) =>
        <TicketCard key={index} onClick={goToTicket} ticket={ticket}></TicketCard>
    );

    return (
        <div className="props-page-body-dark">
            <div className="games-header">My Invites</div>
            {invitedTickets.length > 0 &&
                <div className="games-description">Below are tickets you have been invited to from your friends. Select
                    a ticket below or enter your Invite Code to get started.</div>
            }
            {invitedTickets.length === 0 &&
                <div className="games-description">You have no outstanding invites. If you have an Invite Code from a
                    friend, enter it below.</div>}
            {invitedTickets}
            <SubmitTextField onSubmit={(ticketCode) => goToTicket(ticketCode)}
                             label="Invite Code"
                             placeholder="XXXXX"></SubmitTextField>
            <br/>
            <div className="games-description"> Want to start your own game? Contact <a>ryan@propsbuddy.com</a> to get started.
            </div>
        </div>
    )
}