import React, {useState} from 'react';
import PropTypes from "prop-types";
import PbContainerHeader from "./PbContainerHeader";

const PbContainer = ({label, iconClass, actionButton, collapsible, onClick, defaultCollapsed, children}) => {
    const [collapsed, setCollapsed] = useState(defaultCollapsed);

    const onHeaderClick = () => {
        if (collapsible) {
            setCollapsed(!collapsed)
            return;
        }

        if (onClick) {
            onClick();
        }
    }

    return (
        <div className={`pb-container ${onClick ? 'clickable' : ''}`}>
            <PbContainerHeader label={label}
                               iconClass={iconClass}
                               actionButton={actionButton}
                               collapsed={collapsed}
                               collapsible={collapsible}
                               onClick={onHeaderClick}
            ></PbContainerHeader>
            <div className="pb-container-body" onClick={onClick}>
                {!collapsed &&
                    children
                }
            </div>
        </div>
    )
}

PbContainer.propTypes = {
    label: PropTypes.string.isRequired,
    iconClass: PropTypes.string,
    actionButton: PropTypes.node,
    collapsible: PropTypes.bool,
    defaultCollapsed: PropTypes.bool
}

export default PbContainer;