import React from 'react'
import PropTypes from "prop-types";

import DateService from '../../../../shared/services/DateService';

import PbContainer from "../../../common/containers/PbContainer";
import {TicketDetails} from "../game-item/TicketDetails";
import {translate} from "../../../../shared/services/LocalizationService";

const TicketCard = ({ticket, onClick}) => {
    const gameDate = DateService.parseDate(ticket.startTime, 'MMM D/YY');
    const startTime = DateService.parseDate(ticket.startTime, 'h:mm A');

    const ticketFormat = ticket.games && ticket.games.length > 1
        ? "Props (Multi-Game)"
        : "Props (Single-Game)";

    return (
        <div className="ticket-card">
            <PbContainer label={ticket.name} onClick={() => onClick(ticket.code)}
                         actionButton={<TicketDetails ticket={ticket}></TicketDetails>}>
                <div className="pb-container-body">
                    <div className="ticket-date">
                        <div>
                            {gameDate}
                        </div>
                        <div>
                            {startTime}
                        </div>
                    </div>
                    <div className="ticket-card-label">
                        <div>{ticketFormat}</div>
                        <div className="ticket-card-sub-label">Format</div>
                    </div>
                    <div className="ticket-card-label">
                        <div>{ticket.adminName}</div>
                        <div className="ticket-card-sub-label">Host</div>
                    </div>
                    <div className="ticket-card-label">
                        <div>{translate(ticket.status)}</div>
                        <div className="ticket-card-sub-label">Status</div>
                    </div>
                </div>
            </PbContainer>
        </div>
    );

};

TicketCard.propTypes = {
    ticket: PropTypes.object.isRequired,
    onClick: PropTypes.func
};

export default TicketCard;