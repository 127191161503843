import React from 'react'
import PropTypes from "prop-types";
import EditPropModal from "./EditPropModal";
import {buildModal, useModal} from "../../common/modals/Modal";
import * as PropBuilderService from "../../../shared/services/PropBuilderService";
import PropValuePill from "../../common/widgets/PropValuePill";
import PropType from "../../../shared/constants/PropType";
import {PropHint} from "../../common/widgets/PropHint";

const PropBuilderRow = ({prop, orderBefore, orderAfter, deleteProp, updateProp}) => {

    const {setModal} = useModal();

    const deletePropRow = () => {
        deleteProp();
    }

    const editProp = () => {
        const modal = <EditPropModal prop={prop} onPropSaved={updateProp}></EditPropModal>;
        setModal(modal);
    }

    const updateOrder = (direction) => {
        let newOrder;
        if (direction === 1) {
            newOrder = orderAfter + 1;
        } else {
            newOrder = orderBefore - 1;
        }
        const request = {
            order: newOrder
        }
        PropBuilderService.editProp(prop.id, request).then(result => {
                updateProp(result);
            },
            error => {
                const modal = buildModal("Error", "Couldn't update prop order.");
                setModal(modal);
                console.error(error);
            }
        );
    }

    const getValueLabel = () => {
        if (prop.type === PropType.OVER_UNDER) {
            return "o/u";
        } else if (prop.stat === "SPREAD") {
            return "HOME";
        }
    }

    return (
        <div className="prop-item-container">
            <div className="prop-item-description">{prop.description}{prop.hint && <PropHint prop={prop}></PropHint>}</div>
            {!!prop.value && <PropValuePill label={getValueLabel()} value={prop.value}></PropValuePill>}
            <div className="action-btns">
                {orderBefore && <a className="fas fa-arrow-up" onClick={() => updateOrder(-1)}></a>}
                {orderAfter && <a className="fas fa-arrow-down" onClick={() => updateOrder(1)}></a>}
                <a className="fas fa-pencil-alt" onClick={editProp}></a>
                <a className="fas fa-trash" onClick={deletePropRow}></a>
            </div>
        </div>
    )
}

PropBuilderRow.propTypes = {
    prop: PropTypes.object.isRequired,
    updateProp: PropTypes.func.isRequired,
    deleteProp: PropTypes.func.isRequired
};

export default PropBuilderRow;